@tailwind base;

@layer base {
  html {
    @apply text-gray-900;
  }
}

@tailwind components;
@tailwind utilities;

@media print {
  * {
    -webkit-print-color-adjust: exact;
    -webkit-filter: opacity(1);
  }
}

span {
  -webkit-filter: opacity(1);
}
